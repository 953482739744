import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2';
import {addDoc, collection, doc, getDoc} from 'firebase/firestore';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {auth, db, storage} from '../firebase';
import {useLocation, useNavigate} from 'react-router-dom';
import {onAuthStateChanged} from 'firebase/auth';
import useStore from '../store';
import {nanoid} from 'nanoid';
import PresetServiceSelector from './PresetServiceSelector';
import TrialStatus from "./TrialStatus";
import LanguageContext from "./LanguageContext";
// import {PhoneInput} from "react-international-phone";
// import {handlePhoneChange} from "react-international-phone/dist/utils/handlePhoneChange";
import {Building2, Check, Clock, Image, Info, Lock, Scissors, Send, Store, Users2} from 'lucide-react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import GoogleBusinessStep from "./GoogleBusinessStep";
import CustomSwal from "./CustomSwal";
import EnhancedAvailabilitySelector from './EnhancedAvailabilitySelector';
import {AnimatePresence, motion} from 'framer-motion';
import {useMediaQuery} from 'react-responsive'
import BarbershopEditor from "./BarbershopEditor";
import ShopCategorySelector from "./ShopCategorySelector";
import EmployeeManagementStep from "./EmployeeManagementStep";

const CreationSteps = ({currentStep = 1, onStepClick, isPublished = false}) => {

    const isMobile = useMediaQuery({maxWidth: 768});

    const steps = [
        {id: 1, title: 'Salon', icon: Store},
        {id: 2, title: 'Availability', icon: Clock},
        {id: 3, title: 'Images', icon: Image},
        {id: 4, title: 'Services', icon: Scissors},
        {id: 5, title: 'Team', icon: Users2},
        {id: 6, title: 'Business', icon: Building2},
        {id: 7, title: 'Publish', icon: Send, protected: true}
    ];

    const getStepStatus = (stepId) => {
        if (stepId < currentStep) return 'completed';
        if (stepId === currentStep) return 'current';
        return 'upcoming';
    };

    const handleStepClick = (stepId, isProtected) => {
        // If the step is the publish step (id: 6) and we're not already published,
        // prevent direct navigation
        if (isProtected && currentStep < 6) {
            return;
        }

        // Otherwise, proceed with normal navigation
        if (!isPublished) {
            onStepClick(stepId);
        }
    };

    if (isMobile) {
        const currentStepData = steps[currentStep - 1];
        const progress = ((currentStep - 1) / (steps.length - 1)) * 100;

        return (
            <div className="w-full px-4 py-6">
                {/* Progress Bar */}
                <div className="relative h-2 mb-8 bg-base-200 rounded-full overflow-hidden">
                    <motion.div
                        className="absolute top-0 left-0 h-full bg-primary"
                        initial={{width: `${((currentStep - 2) / (steps.length - 1)) * 100}%`}}
                        animate={{width: `${progress}%`}}
                        transition={{duration: 0.5, ease: "easeInOut"}}
                    />
                    <div
                        className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer"
                        style={{backgroundSize: '200% 100%'}}/>
                </div>

                {/* Step Counter */}
                <div className="text-sm text-base-content/60 mb-2 text-center">
                    Step {currentStep} of {steps.length}
                </div>

                {/* Current Step Display */}
                <AnimatePresence mode="wait">
                    <motion.div
                        key={currentStep}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -20}}
                        transition={{duration: 0.3}}
                        className="flex flex-col items-center"
                    >
                        {/* Icon Circle */}
                        <motion.div
                            className={`
                                relative w-16 h-16 rounded-full mb-4
                                flex items-center justify-center
                                ${currentStepData.protected && currentStep < 6 ? 'bg-base-200' : 'bg-primary'}
                                shadow-lg
                            `}
                            initial={{scale: 0.8}}
                            animate={{scale: 1}}
                            transition={{type: "spring", stiffness: 200, damping: 15}}
                        >
                            {currentStepData.protected && currentStep < 6 ? (
                                <Lock className="w-8 h-8 text-base-content/40"/>
                            ) : (
                                <currentStepData.icon className="w-8 h-8 text-primary-content"/>
                            )}

                            {/* Pulse Effect */}
                            <div className="absolute inset-0 rounded-full animate-ping bg-primary opacity-20"/>

                            {/* Rotating Highlight */}
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-r from-transparent via-white/30 to-transparent animate-rotate"/>
                        </motion.div>

                        {/* Step Title */}
                        <motion.h3
                            className="text-xl font-semibold text-center mb-2"
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{delay: 0.2}}
                        >
                            {currentStepData.title}
                        </motion.h3>

                        {/* Steps Navigation */}
                        <div className="flex justify-center space-x-2 mt-4">
                            {steps.map((step) => (
                                <motion.button
                                    key={step.id}
                                    className={`w-2 h-2 rounded-full transition-all duration-300 ${
                                        step.id === currentStep
                                            ? 'w-4 bg-primary'
                                            : step.id < currentStep
                                                ? 'bg-primary/40'
                                                : 'bg-base-300'
                                    }`}
                                    onClick={() => handleStepClick(step.id, step.protected)}
                                    whileTap={{scale: 0.9}}
                                />
                            ))}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        );
    }

    return (
        <div className="w-full px-4 py-8">
            <div className="relative">
                {/* Progress Line */}
                <div className="absolute top-1/2 left-0 w-full h-1 bg-gray-200 -translate-y-1/2"/>
                <div
                    className="absolute top-1/2 left-0 h-1 bg-primary transition-all duration-500 -translate-y-1/2"
                    style={{width: `${(Math.max(0, currentStep - 1) / (steps.length - 1)) * 100}%`}}
                />

                {/* Steps */}
                <div className="relative flex justify-between">
                    {steps.map((step) => {
                        const status = getStepStatus(step.id);
                        const Icon = step.icon;
                        const isPublishStep = step.id === 6;
                        const isProtectedStep = isPublishStep && currentStep < 6;

                        return (
                            <div
                                key={step.id}
                                className={`flex flex-col items-center ${
                                    isProtectedStep ? 'cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                onClick={() => handleStepClick(step.id, step.protected)}
                            >
                                <div className={`
                  relative flex items-center justify-center w-12 h-12 rounded-full
                  transition-all duration-300
                  ${status === 'completed' ? 'bg-primary text-white shadow-lg shadow-primary/30' :
                                    status === 'current' ? 'bg-primary text-white scale-110 shadow-xl shadow-primary/40' :
                                        isProtectedStep ? 'bg-gray-100 border-2 border-gray-300 text-gray-400' :
                                            'bg-white border-2 border-gray-200 text-gray-400'}
                  ${isPublished ? 'opacity-80' : ''}
                  ${!isProtectedStep && !isPublished ? 'hover:scale-105' : ''}
                `}>
                                    {status === 'completed' ? (
                                        <Check className="w-6 h-6 animate-appear"/>
                                    ) : isProtectedStep ? (
                                        <Lock className="w-5 h-5"/>
                                    ) : (
                                        <Icon
                                            className={`w-6 h-6 ${status === 'current' ? 'animate-bounce-subtle' : ''}`}/>
                                    )}

                                    {/* Pulse Effect for Current Step */}
                                    {status === 'current' && !isProtectedStep && (
                                        <div
                                            className="absolute w-full h-full rounded-full animate-ping bg-primary opacity-20"/>
                                    )}
                                </div>

                                {/* Step Title */}
                                <span className={`
                  mt-2 text-sm font-medium transition-colors duration-300
                  ${status === 'completed' ? 'text-primary' :
                                    status === 'current' ? 'text-primary font-semibold' :
                                        isProtectedStep ? 'text-gray-400' :
                                            'text-gray-400'}
                `}>
                  {step.title}
                </span>

                                {/* Lock indicator for protected step */}
                                {isProtectedStep && (
                                    <span className="text-xs text-gray-400 mt-1">
                    Complete previous steps first
                  </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            <style jsx>{`
                @keyframes appear {
                    from {
                        transform: scale(0);
                        opacity: 0;
                    }
                    to {
                        transform: scale(1);
                        opacity: 1;
                    }
                }

                @keyframes bounce-subtle {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-2px);
                    }
                }

                .animate-appear {
                    animation: appear 0.3s ease-out;
                }

                .animate-bounce-subtle {
                    animation: bounce-subtle 2s infinite;
                }
            `}</style>
        </div>
    );
};

const BARBERSHOP_TEMPLATES = [
    {
        title: 'Professional Barbershop',
        description: 'A clean, professional template',
        content: `<div class="shop-description">
      <h2>Welcome to [Your Shop Name]</h2>
      <p>With [X] years of experience in the art of barbering, we take pride in delivering exceptional grooming services to our clients.</p>
      <h3>Our Expertise</h3>
      <ul>
        <li>Classic and modern haircuts</li>
        <li>Precise beard trimming and styling</li>
        <li>Hot towel shaves</li>
        <li>Hair styling and treatments</li>
      </ul>
      <h3>Why Choose Us?</h3>
      <ul>
        <li>Experienced and skilled barbers</li>
        <li>Clean and modern facility</li>
        <li>Relaxed, friendly atmosphere</li>
        <li>Attention to detail</li>
      </ul>
      <p>Visit us today for a premium grooming experience!</p>
    </div>`
    },
    {
        title: 'Traditional Barbershop',
        description: 'A classic, traditional style',
        content: `<div class="shop-description">
      <h2>Traditional Craftsmanship at [Your Shop Name]</h2>
      <p>Step into a world where traditional barbering meets modern style. Our classic barbershop brings timeless grooming techniques to the modern gentleman.</p>
      <h3>Our Services</h3>
      <ul>
        <li>Traditional hot towel shaves</li>
        <li>Classic gentleman's haircuts</li>
        <li>Father & son haircuts</li>
        <li>Beard grooming</li>
      </ul>
      <h3>The Experience</h3>
      <ul>
        <li>Old-school barbershop atmosphere</li>
        <li>Traditional techniques</li>
        <li>Quality grooming products</li>
        <li>Experienced barbers</li>
      </ul>
      <p>Experience the art of traditional barbering!</p>
    </div>`
    },
    {
        title: 'Modern Style Studio',
        description: 'A contemporary, trendy approach',
        content: `<div class="shop-description">
      <h2>[Your Shop Name] - Modern Style Studio</h2>
      <p>We're not just a barbershop - we're a modern grooming destination where style meets precision.</p>
      <h3>Signature Services</h3>
      <ul>
        <li>Contemporary fade techniques</li>
        <li>Modern beard design</li>
        <li>Hair color and highlights</li>
        <li>Skin fade specialists</li>
      </ul>
      <h3>The Experience</h3>
      <ul>
        <li>Trendsetting styles</li>
        <li>Premium products</li>
        <li>Skilled style consultants</li>
        <li>Modern atmosphere</li>
      </ul>
      <p>Transform your look with us!</p>
    </div>`
    }
];

const CUSTOM_STYLES = `
  .shop-description {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  .shop-description h2 {
    color: #2c5282;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .shop-description h3 {
    color: #4a5568;
    font-size: 20px;
    margin: 16px 0 8px 0;
  }
  .shop-description ul {
    margin-left: 20px;
    margin-bottom: 16px;
  }
  .shop-description li {
    margin-bottom: 8px;
  }
  .shop-description p {
    margin-bottom: 16px;
  }
`;

const CreateBarberShop = () => {
    const {language} = useContext(LanguageContext);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [shopName, setShopName] = useState('');
    const [address, setAddress] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
    const [images, setImages] = useState([]);
    const [services, setServices] = useState([{name: '', price: '', duration: ''}]);
    const [currentStep, setCurrentStep] = useState(1);
    const editorRef = useRef(null);
    const setUserShops = useStore(state => state.setUserShops);
    const userShops = useStore(state => state.userShops);
    // const [phoneNumber, setPhoneNumber] = useState('');
    // const [countryCode, setCountryCode] = useState('+90');
    const [email, setEmail] = useState(auth.currentUser?.email || '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const [isPublished, setIsPublished] = useState(false);
    const [trialStatus, setTrialStatus] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(() => {
        // Fetch the phone number from the current user's data in Firestore
        const docRef = doc(db, 'users', auth.currentUser?.uid);
        getDoc(docRef).then(docSnap => {
            if (docSnap.exists() && docSnap.data().phoneNumber) {
                setPhoneNumber(docSnap.data().phoneNumber);
            }
        });
        return '';
    });
    const [editorKey, setEditorKey] = useState(0);
    const [editorContent, setEditorContent] = useState('');
    const [specialDates, setSpecialDates] = useState({});
    const [isAddingCustomAddress, setIsAddingCustomAddress] = useState(false);
    const addressInputRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [formTouched, setFormTouched] = useState(false);
    const [tempShopId, setTempShopId] = useState(null);

    const MAPBOX_TOKEN = 'pk.eyJ1Ijoib3RobWFuYWRpYmFyYmVyc2hvcCIsImEiOiJjbHk5NTR1aWgwZW0yMm5xdzE2dmliZWZvIn0.XS8JZjdd1O8YIs_VXuUcPA';

    const [availability, setAvailability] = useState({
        Monday: null,
        Tuesday: null,
        Wednesday: null,
        Thursday: null,
        Friday: null,
        Saturday: null,
        Sunday: null
    });

    useEffect(() => {
        setEditorKey(prev => prev + 1);
    }, [language]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setImages([...images, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))]);
        }
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setEmail(currentUser.email); // Add this line
            } else {
                navigate('/auth'); // Redirect to auth page if not logged in
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const checkTrialStatus = async () => {
            if (user) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    const userData = userDoc.data();
                    if (userData) {
                        const now = new Date();
                        const trialEndDate = userData.trialEndDate.toDate();
                        const isSubscribed = userData.isSubscribed;

                        if (isSubscribed) {
                            setTrialStatus('subscribed');
                        } else if (now < trialEndDate) {
                            setTrialStatus('active');
                        } else {
                            setTrialStatus('expired');
                        }
                    }
                } catch (error) {
                    console.error('Error checking trial status:', error);
                }
            }
        };

        checkTrialStatus();
    }, [user]);

    useEffect(() => {
        // Reset form when navigating to this component
        if (location.pathname === '/create-shop') {
            resetForm();
        }
    }, [location]);

    useEffect(() => {
        let timeoutId;
        if (address.length > 3 && !isSuggestionSelected) {
            setIsLoadingSuggestions(true);
            timeoutId = setTimeout(() => {
                fetchAddressSuggestions(address);
            }, 500); // Increased debounce time
        } else {
            setAddressSuggestions([]);
        }

        return () => clearTimeout(timeoutId);
    }, [address, isSuggestionSelected]);

    useEffect(() => {
        setShopData({
            name: shopName,
            address: address,
            phoneNumber: phoneNumber,
            email: email,
            description: editorContent,
            services: services,
            availability: availability,
            images: images,
            specialDates: specialDates,
            categories: categories,
            pricingTier: calculatePricingTier(services)
        });
    }, [
        shopName,
        address,
        phoneNumber,
        email,
        editorContent,
        services,
        availability,
        images,
        specialDates,
        categories
    ]);

    const [shopData, setShopData] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        email: auth.currentUser?.email || '',
        description: '',
        services: [],
        availability: {},
        images: [],
        specialDates: {},
        categories: [],
        pricingTier: '€'
    });

    if (trialStatus === 'expired') {
        return (
            <div className="container mx-auto mt-10">
                <h1 className="text-2xl font-bold mb-4">Create Your Barber Shop</h1>
                <TrialStatus/>
                <p className="mt-4">Your trial has expired. Please subscribe to create a barber shop.</p>
                <button
                    onClick={() => navigate('/subscribe')}
                    className="mt-4 bg-blue-500 text-white p-2 rounded"
                >
                    Subscribe Now
                </button>
            </div>
        );
    }

    const fetchAddressSuggestions = async (input) => {
        try {
            const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?access_token=${MAPBOX_TOKEN}&types=address`);
            if (!response.ok) {
                throw new Error('Failed to fetch address suggestions');
            }
            const data = await response.json();
            setAddressSuggestions(data.features.map(feature => feature.place_name));
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
            // Implement user feedback here, e.g., show an error message
        } finally {
            setIsLoadingSuggestions(false);
        }
    };


    const handleServiceChange = (index, field, value) => {
        const updatedServices = services.map((service, i) => {
            if (i === index) {
                if (field === 'price') {
                    // Only allow numbers for price
                    value = value.replace(/[^0-9]/g, '');
                }
                if (field === 'duration') {
                    // Only allow numbers between 0-999
                    value = value.replace(/[^0-9]/g, '').slice(0, 3);
                }
                return {...service, [field]: value};
            }
            return service;
        });
        setServices(updatedServices);
    };

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
    };

    // const countryCodes = [
    //     {code: '+93', country: 'AF'}, {code: '+355', country: 'AL'}, {code: '+213', country: 'DZ'},
    //     {code: '+1684', country: 'AS'}, {code: '+376', country: 'AD'}, {code: '+244', country: 'AO'},
    //     {code: '+1264', country: 'AI'}, {code: '+672', country: 'AQ'}, {code: '+1268', country: 'AG'},
    //     {code: '+54', country: 'AR'}, {code: '+374', country: 'AM'}, {code: '+297', country: 'AW'},
    //     {code: '+61', country: 'AU'}, {code: '+43', country: 'AT'}, {code: '+994', country: 'AZ'},
    //     {code: '+1242', country: 'BS'}, {code: '+973', country: 'BH'}, {code: '+880', country: 'BD'},
    //     {code: '+1246', country: 'BB'}, {code: '+375', country: 'BY'}, {code: '+32', country: 'BE'},
    //     {code: '+501', country: 'BZ'}, {code: '+229', country: 'BJ'}, {code: '+1441', country: 'BM'},
    //     {code: '+975', country: 'BT'}, {code: '+591', country: 'BO'}, {code: '+387', country: 'BA'},
    //     {code: '+267', country: 'BW'}, {code: '+55', country: 'BR'}, {code: '+246', country: 'IO'},
    //     {code: '+1284', country: 'VG'}, {code: '+673', country: 'BN'}, {code: '+359', country: 'BG'},
    //     {code: '+226', country: 'BF'}, {code: '+257', country: 'BI'}, {code: '+855', country: 'KH'},
    //     {code: '+237', country: 'CM'}, {code: '+1', country: 'CA'}, {code: '+238', country: 'CV'},
    //     {code: '+1345', country: 'KY'}, {code: '+236', country: 'CF'}, {code: '+235', country: 'TD'},
    //     {code: '+56', country: 'CL'}, {code: '+86', country: 'CN'}, {code: '+61', country: 'CX'},
    //     {code: '+61', country: 'CC'}, {code: '+57', country: 'CO'}, {code: '+269', country: 'KM'},
    //     {code: '+242', country: 'CG'}, {code: '+243', country: 'CD'}, {code: '+682', country: 'CK'},
    //     {code: '+506', country: 'CR'}, {code: '+385', country: 'HR'}, {code: '+53', country: 'CU'},
    //     {code: '+599', country: 'CW'}, {code: '+357', country: 'CY'}, {code: '+420', country: 'CZ'},
    //     {code: '+45', country: 'DK'}, {code: '+253', country: 'DJ'}, {code: '+1767', country: 'DM'},
    //     {code: '+1849', country: 'DO'}, {code: '+593', country: 'EC'}, {code: '+20', country: 'EG'},
    //     {code: '+503', country: 'SV'}, {code: '+240', country: 'GQ'}, {code: '+291', country: 'ER'},
    //     {code: '+372', country: 'EE'}, {code: '+251', country: 'ET'}, {code: '+500', country: 'FK'},
    //     {code: '+298', country: 'FO'}, {code: '+679', country: 'FJ'}, {code: '+358', country: 'FI'},
    //     {code: '+33', country: 'FR'}, {code: '+594', country: 'GF'}, {code: '+689', country: 'PF'},
    //     {code: '+241', country: 'GA'}, {code: '+220', country: 'GM'}, {code: '+995', country: 'GE'},
    //     {code: '+49', country: 'DE'}, {code: '+233', country: 'GH'}, {code: '+350', country: 'GI'},
    //     {code: '+30', country: 'GR'}, {code: '+299', country: 'GL'}, {code: '+1473', country: 'GD'},
    //     {code: '+590', country: 'GP'}, {code: '+1671', country: 'GU'}, {code: '+502', country: 'GT'},
    //     {code: '+44', country: 'GG'}, {code: '+224', country: 'GN'}, {code: '+245', country: 'GW'},
    //     {code: '+592', country: 'GY'}, {code: '+509', country: 'HT'}, {code: '+504', country: 'HN'},
    //     {code: '+852', country: 'HK'}, {code: '+36', country: 'HU'}, {code: '+354', country: 'IS'},
    //     {code: '+91', country: 'IN'}, {code: '+62', country: 'ID'}, {code: '+98', country: 'IR'},
    //     {code: '+964', country: 'IQ'}, {code: '+353', country: 'IE'}, {code: '+44', country: 'IM'},
    //     {code: '+972', country: 'IL'}, {code: '+39', country: 'IT'}, {code: '+225', country: 'CI'},
    //     {code: '+1876', country: 'JM'}, {code: '+81', country: 'JP'}, {code: '+44', country: 'JE'},
    //     {code: '+962', country: 'JO'}, {code: '+7', country: 'KZ'}, {code: '+254', country: 'KE'},
    //     {code: '+686', country: 'KI'}, {code: '+383', country: 'XK'}, {code: '+965', country: 'KW'},
    //     {code: '+996', country: 'KG'}, {code: '+856', country: 'LA'}, {code: '+371', country: 'LV'},
    //     {code: '+961', country: 'LB'}, {code: '+266', country: 'LS'}, {code: '+231', country: 'LR'},
    //     {code: '+218', country: 'LY'}, {code: '+423', country: 'LI'}, {code: '+370', country: 'LT'},
    //     {code: '+352', country: 'LU'}, {code: '+853', country: 'MO'}, {code: '+389', country: 'MK'},
    //     {code: '+261', country: 'MG'}, {code: '+265', country: 'MW'}, {code: '+60', country: 'MY'},
    //     {code: '+960', country: 'MV'}, {code: '+223', country: 'ML'}, {code: '+356', country: 'MT'},
    //     {code: '+692', country: 'MH'}, {code: '+596', country: 'MQ'}, {code: '+222', country: 'MR'},
    //     {code: '+230', country: 'MU'}, {code: '+262', country: 'YT'}, {code: '+52', country: 'MX'},
    //     {code: '+691', country: 'FM'}, {code: '+373', country: 'MD'}, {code: '+377', country: 'MC'},
    //     {code: '+976', country: 'MN'}, {code: '+382', country: 'ME'}, {code: '+1664', country: 'MS'},
    //     {code: '+212', country: 'MA'}, {code: '+258', country: 'MZ'}, {code: '+95', country: 'MM'},
    //     {code: '+264', country: 'NA'}, {code: '+674', country: 'NR'}, {code: '+977', country: 'NP'},
    //     {code: '+31', country: 'NL'}, {code: '+687', country: 'NC'}, {code: '+64', country: 'NZ'},
    //     {code: '+505', country: 'NI'}, {code: '+227', country: 'NE'}, {code: '+234', country: 'NG'},
    //     {code: '+683', country: 'NU'}, {code: '+672', country: 'NF'}, {code: '+850', country: 'KP'},
    //     {code: '+1670', country: 'MP'}, {code: '+47', country: 'NO'}, {code: '+968', country: 'OM'},
    //     {code: '+92', country: 'PK'}, {code: '+680', country: 'PW'}, {code: '+970', country: 'PS'},
    //     {code: '+507', country: 'PA'}, {code: '+675', country: 'PG'}, {code: '+595', country: 'PY'},
    //     {code: '+51', country: 'PE'}, {code: '+63', country: 'PH'}, {code: '+48', country: 'PL'},
    //     {code: '+351', country: 'PT'}, {code: '+1', country: 'PR'}, {code: '+974', country: 'QA'},
    //     {code: '+262', country: 'RE'}, {code: '+40', country: 'RO'}, {code: '+7', country: 'RU'},
    //     {code: '+250', country: 'RW'}, {code: '+590', country: 'BL'}, {code: '+290', country: 'SH'},
    //     {code: '+1869', country: 'KN'}, {code: '+1758', country: 'LC'}, {code: '+590', country: 'MF'},
    //     {code: '+508', country: 'PM'}, {code: '+1784', country: 'VC'}, {code: '+685', country: 'WS'},
    //     {code: '+378', country: 'SM'}, {code: '+239', country: 'ST'}, {code: '+966', country: 'SA'},
    //     {code: '+221', country: 'SN'}, {code: '+381', country: 'RS'}, {code: '+248', country: 'SC'},
    //     {code: '+232', country: 'SL'}, {code: '+65', country: 'SG'}, {code: '+1721', country: 'SX'},
    //     {code: '+421', country: 'SK'}, {code: '+386', country: 'SI'}, {code: '+677', country: 'SB'},
    //     {code: '+252', country: 'SO'}, {code: '+27', country: 'ZA'}, {code: '+82', country: 'KR'},
    //     {code: '+211', country: 'SS'}, {code: '+34', country: 'ES'}, {code: '+94', country: 'LK'},
    //     {code: '+249', country: 'SD'}, {code: '+597', country: 'SR'}, {code: '+47', country: 'SJ'},
    //     {code: '+268', country: 'SZ'}, {code: '+46', country: 'SE'}, {code: '+41', country: 'CH'},
    //     {code: '+963', country: 'SY'}, {code: '+886', country: 'TW'}, {code: '+992', country: 'TJ'},
    //     {code: '+255', country: 'TZ'}, {code: '+66', country: 'TH'}, {code: '+670', country: 'TL'},
    //     {code: '+228', country: 'TG'}, {code: '+690', country: 'TK'}, {code: '+676', country: 'TO'},
    //     {code: '+1868', country: 'TT'}, {code: '+216', country: 'TN'}, {code: '+90', country: 'TR'},
    //     {code: '+993', country: 'TM'}, {code: '+1649', country: 'TC'}, {code: '+688', country: 'TV'},
    //     {code: '+1340', country: 'VI'}, {code: '+256', country: 'UG'}, {code: '+380', country: 'UA'},
    //     {code: '+971', country: 'AE'}, {code: '+44', country: 'GB'}, {code: '+1', country: 'US'},
    //     {code: '+598', country: 'UY'}, {code: '+998', country: 'UZ'}, {code: '+678', country: 'VU'},
    //     {code: '+379', country: 'VA'}, {code: '+58', country: 'VE'}, {code: '+84', country: 'VN'},
    //     {code: '+681', country: 'WF'}, {code: '+212', country: 'EH'}, {code: '+967', country: 'YE'},
    //     {code: '+260', country: 'ZM'}, {code: '+263', country: 'ZW'}
    // ];

    const phoneInputStyle = {
        width: '100%',
        height: '2.5rem',
        fontSize: '1rem',
        borderRadius: '0.375rem',
        border: '1px solid rgb(209, 213, 219)',
        padding: '0.5rem 0.75rem',
        paddingLeft: '60px', // Make room for the flag
    }

    const addService = () => {
        setServices([...services, {name: '', price: '', duration: ''}]);
    };

    const removeService = (index) => {
        setServices(services.filter((_, i) => i !== index));
    };

    const handlePresetServiceSelect = (presetService) => {
        const existingServiceIndex = services.findIndex(service =>
            service.name === presetService.name &&
            service.price === presetService.price
        );

        if (existingServiceIndex >= 0) {
            setServices(prevServices => prevServices.filter((_, index) => index !== existingServiceIndex));
        } else {
            setServices(prevServices => [...prevServices, {
                name: presetService.name,
                price: presetService.price,
                duration: presetService.duration
            }]);
        }
    };

    const validateStep = (step) => {
        switch (step) {
            case 1:
                if (!shopName.trim() || !address.trim() || !phoneNumber.trim() || !email.trim() || !editorContent.trim() || !categories.length) {
                    CustomSwal.fire({
                        title: 'Missing Information',
                        text: language === 'tr' ? 'Lütfen tüm zorunlu alanları doldurun: Dükkan Adı, Adres, Telefon Numarası, E-posta ve Açıklama.' :
                            language === 'ar' ? 'يرجى ملء جميع الحقول المطلوبة: اسم المتجر، العنوان، رقم الهاتف، البريد الإلكتروني والوصف.' :
                                language === 'de' ? 'Bitte füllen Sie alle Pflichtfelder aus: Geschäftsname, Adresse, Telefonnummer, E-Mail und Beschreibung.' :
                                    'Please fill in all required fields: Shop Name, Address, Phone Number, Email, and Description.',
                        icon: 'info',
                        confirmButtonText: language === 'tr' ? 'Tamam' :
                            language === 'ar' ? 'حسناً' :
                                language === 'de' ? 'OK' : 'OK'
                    });

                    // Highlight empty fields for better UX
                    if (!shopName.trim()) document.getElementById('shopName')?.classList.add('input-error');
                    if (!address.trim()) document.getElementById('address')?.classList.add('input-error');
                    if (!phoneNumber.trim()) document.querySelector('.react-phone-input-2')?.classList.add('error');
                    if (!email.trim()) document.getElementById('email')?.classList.add('input-error');
                    if (!editorContent.trim()) document.querySelector('.tox-tinymce')?.classList.add('border-error');

                    // Remove error highlights after 3 seconds
                    setTimeout(() => {
                        document.querySelectorAll('.input-error, .error').forEach(el => {
                            el.classList.remove('input-error', 'error');
                        });
                        document.querySelector('.tox-tinymce')?.classList.remove('border-error');
                    }, 3000);

                    return false;
                }
                return true;
            case 2:
                if (Object.values(availability).every(day => day === null)) {
                    Swal.fire({
                        title: 'Store Hours Required',
                        text: 'Please set your store hours for at least one day of the week.',
                        icon: 'info',
                        confirmButtonText: 'OK'
                    });
                    return false;
                }
                return true;
            case 3:
                if (images.length === 0) {
                    Swal.fire({
                        title: 'No Images Uploaded',
                        text: 'Adding photos of your shop can attract more customers. Would you like to continue without images?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Continue without images',
                        cancelButtonText: 'I\'ll add images'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setCurrentStep(4);
                        }
                    });
                    return false;
                }
                return true;
            case 4:
                const validServices = services.filter(service =>
                    service.name.trim() &&
                    service.price.trim() &&
                    service.duration.trim()
                );
                setServices(validServices);
                if (validServices.length === 0) {
                    Swal.fire({
                        title: 'No Services Added',
                        text: 'Please add at least one service with name, price, and duration.',
                        icon: 'info',
                        confirmButtonText: 'OK'
                    });
                    return false;
                }
                setServices(validServices);
                return true;
            default:
                return true;
        }
    };

    const createTempShop = async () => {
        const docRef = await addDoc(collection(db, 'barberShops'), {
            temporary: true,
            createdAt: new Date(),
            ownerId: user.uid
        });
        setTempShopId(docRef.id);
        return docRef.id;
    };

    const handleStepChange = async (step) => {
        // Don't allow step changes if published
        if (isPublished) return;

        // Don't allow skipping to step 6 (publish) before Google Business step
        if (step === 6 && currentStep < 5) {
            setCurrentStep(5); // Go to Google Business step instead
            return;
        }

        // Check if we're moving to step 5 and need to create temp shop
        if (step === 5 && !tempShopId) {
            try {
                const id = await createTempShop();
                if (step < currentStep || validateStep(currentStep)) {
                    setCurrentStep(step);
                }
            } catch (error) {
                console.error('Error creating temporary shop:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an error preparing the employee management step. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } else {
            // Allow going back or if current step is validated
            if (step < currentStep || validateStep(currentStep)) {
                setCurrentStep(step);
            }
        }
    };

    const resetForm = () => {
        setShopName('');
        setAddress('');
        setAddressSuggestions([]);
        setIsSuggestionSelected(false);
        setImages([]);
        setServices([{name: '', price: ''}]);
        setCurrentStep(1);
        setPhoneNumber('');
        setEmail('');
        setIsSubmitting(false);
        setIsPublished(false);
        if (editorRef.current) {
            editorRef.current.setContent('');
        }
    };

    // useEffect(() => {
    //     const checkTrialStatus = async () => {
    //         if (user) {
    //             try {
    //                 const userData = await getUserData(user.uid);
    //                 const now = new Date();
    //                 const trialEndDate = userData.trialEndDate.toDate();
    //
    //                 if (now > trialEndDate && !userData.isSubscribed) {
    //                     setTrialStatus('expired');
    //                 } else if (userData.isSubscribed) {
    //                     setTrialStatus('subscribed');
    //                 } else {
    //                     setTrialStatus('active');
    //                 }
    //             } catch (error) {
    //                 console.error('Error checking trial status:', error);
    //             }
    //         }
    //     };
    //
    //     checkTrialStatus();
    // }, [user]);

    // const cleanPhoneNumber = (countryCode, phoneNumber) => {
    //     // Remove any non-digit characters from the phone number
    //     const digitsOnly = phoneNumber.replace(/\D/g, '');
    //
    //     // Remove the country code from the beginning of the phone number if it's present
    //     const phoneWithoutCountryCode = digitsOnly.startsWith(countryCode.slice(1))
    //         ? digitsOnly.slice(countryCode.length - 1)
    //         : digitsOnly;
    //
    //     // Remove any leading zeros
    //     const cleanedNumber = phoneWithoutCountryCode.replace(/^0+/, '');
    //
    //     return cleanedNumber;
    // };

    const handleGoogleBusinessCreation = async (profileData) => {
        if (!profileData.wantsToCreate) {
            setCurrentStep(7);
            setIsPublished(true);
            return;
        }

        try {
            // Initialize Google API client and handle authorization
            // Make API calls to create the business profile
            // Handle verification process
            // Update local database with Google Business Profile ID

            // Navigate to success page or account dashboard
            setCurrentStep(7);
            setIsPublished(true);
        } catch (error) {
            console.error('Error creating Google Business Profile:', error);
            Swal.fire({
                title: 'Error',
                text: 'There was an error creating your Google Business Profile. You can try again later from your account dashboard.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            // Still move to final step even if Google Business creation fails
            setCurrentStep(7);
            setIsPublished(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentStep !== 6) {
            return;
        }

        if (trialStatus === 'expired') {
            Swal.fire({
                title: 'Trial Expired',
                text: 'Your trial has expired. Please subscribe to continue using our services.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Subscribe Now',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/subscribe');
                }
            });
            return;
        }

        if (validateStep(4) && user) {
            setIsSubmitting(true);
            setIsLoading(true);
            const validServices = services.filter(service => service.name.trim() && service.price.trim());

            try {
                const imageUrls = await Promise.all(images.map(async (image) => {
                    const imageRef = ref(storage, `shops/${user.uid}/${image.name}`);
                    await uploadBytes(imageRef, image);
                    return getDownloadURL(imageRef);
                }));

                const uniqueUrl = nanoid(10);
                const uniqueImageUrls = [...new Set(imageUrls)];
                const validServices = services.filter(service => service.name.trim() && service.price.trim());
                const pricingTier = calculatePricingTier(validServices);

                const shopData = {
                    name: shopName,
                    address: address,
                    phoneNumber: phoneNumber,
                    email: email,
                    biography: editorRef.current ? editorRef.current.getContent() : '',
                    services: validServices,
                    ownerId: user.uid,
                    createdAt: new Date(),
                    uniqueUrl: uniqueUrl,
                    availability: availability,
                    imageUrls: uniqueImageUrls,
                    pricingTier: pricingTier,
                };

                const docRef = await addDoc(collection(db, 'barberShops'), shopData);

                console.log('Barber shop created with ID: ', docRef.id);

                setUserShops(prevShops => [...prevShops, {id: docRef.id, ...shopData}]);

                setIsPublished(true);
                setCurrentStep(6);

                Swal.fire({
                    title: 'Shop Created Successfully',
                    html: `Your shop has been created! Share this link with your clients:<br>
                <strong>${window.location.origin}/shop/${uniqueUrl}</strong>`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } catch (error) {
                console.error('Error creating barber shop: ', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an error creating your barber shop. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                setIsSubmitting(false);
                setIsLoading(false);
            }
        }
    };

    const translations = {
        en: {
            enterBiography: "Describe your barbershop...",
            biographyHelp: "Use the template button to start with a professional layout",
            viewCreatedShops: "View Created Shops",
            createYourBarberShop: "Create Your Barber Shop",
            trialExpired: "Trial Expired",
            trialExpiredMessage: "Your trial has expired. Please subscribe to continue using our services.",
            trialActive: "Trial Active",
            trialActiveMessage: "You are currently in your 14-day trial period.",
            salon: "Salon",
            availability: "Availability",
            images: "Images",
            services: "Services",
            publish: "Publish",
            barberShopName: "Barber Shop Name",
            enterShopName: "Enter your barber shop name",
            address: "Address",
            enterAddress: "Enter your shop's address",
            phoneNumber: "Phone Number",
            enterPhoneNumber: "Enter your phone number",
            email: "Email",
            enterEmail: "Enter your email address",
            biography: "Biography",
            next: "Next",
            back: "Back",
            setYourAvailability: "Set Your Availability",
            dropzoneText: "Drag 'n' drop some images here, or click to select files",
            quickAddServices: "Quick Add Services",
            serviceName: "Service name",
            price: "Price",
            addService: "Add Service",
            creating: "Creating...",
            success: "Success!",
            shopCreatedMessage: "Your barber shop has been created. To activate your shop, please subscribe to our service.",
            subscribeNow: "Subscribe Now - €25/month",
            uniqueLinkMessage: "Your unique shop link will be generated after subscription.",
            createAnotherShop: "Create Another Shop",
            missingInformation: "Missing Information",
            fillAllFields: "Please fill in all required fields: Shop Name, Address, Phone Number, Email, and Biography.",
            storeHoursRequired: "Store Hours Required",
            setStoreHours: "Please set your store hours for at least one day of the week.",
            noImagesUploaded: "No Images Uploaded",
            addImagesAttractCustomers: "Adding photos of your shop can attract more customers. Would you like to continue without images?",
            continueWithoutImages: "Continue without images",
            addImages: "I'll add images",
            noServicesAdded: "No Services Added",
            addAtLeastOneService: "Please add at least one service with both a name and price.",
            shopCreatedSuccessfully: "Shop Created Successfully",
            shopCreatedShareLink: "Your shop has been created! Share this link with your clients:",
            error: "Error",
            creatingShop: "Creating your shop...",
            errorCreatingShop: "There was an error creating your barber shop. Please try again.",
            subscribeToCreate: "Please subscribe to create a barber shop.",
            subscribeNowButton: "Subscribe Now"
        },
        tr: {
            enterBiography: "Berber dükkanınızı tanımlayın...",
            biographyHelp: "Profesyonel bir düzen ile başlamak için şablon düğmesini kullanın",
            viewCreatedShops: "Oluşturulan Dükkanları Görüntüle",
            createYourBarberShop: "Berber Dükkanınızı Oluşturun",
            trialExpired: "Deneme Süresi Sona Erdi",
            trialExpiredMessage: "Deneme süreniz sona erdi. Hizmetlerimizi kullanmaya devam etmek için lütfen abone olun.",
            trialActive: "Deneme Süresi Aktif",
            trialActiveMessage: "Şu anda 14 günlük deneme sürecindesiniz.",
            salon: "Salon",
            availability: "Müsaitlik",
            images: "Görseller",
            services: "Hizmetler",
            publish: "Yayınla",
            barberShopName: "Berber Dükkanı Adı",
            enterShopName: "Berber dükkanınızın adını girin",
            address: "Adres",
            enterAddress: "Dükkanınızın adresini girin",
            phoneNumber: "Telefon Numarası",
            enterPhoneNumber: "Telefon numaranızı girin",
            email: "E-posta",
            enterEmail: "E-posta adresinizi girin",
            biography: "Biyografi",
            next: "İleri",
            back: "Geri",
            setYourAvailability: "Müsaitlik Durumunuzu Ayarlayın",
            dropzoneText: "Resimleri buraya sürükleyip bırakın veya dosya seçmek için tıklayın",
            quickAddServices: "Hızlı Hizmet Ekle",
            serviceName: "Hizmet adı",
            price: "Fiyat",
            addService: "Hizmet Ekle",
            creating: "Oluşturuluyor...",
            success: "Başarılı!",
            shopCreatedMessage: "Berber dükkanınız oluşturuldu. Dükkanınızı aktifleştirmek için lütfen hizmetimize abone olun.",
            subscribeNow: "Şimdi Abone Ol - Aylık €25",
            uniqueLinkMessage: "Benzersiz dükkan linkiniz abonelik sonrası oluşturulacaktır.",
            createAnotherShop: "Başka Bir Dükkan Oluştur",
            missingInformation: "Eksik Bilgi",
            fillAllFields: "Lütfen tüm gerekli alanları doldurun: Dükkan Adı, Adres, Telefon Numarası, E-posta ve Biyografi.",
            storeHoursRequired: "Çalışma Saatleri Gerekli",
            setStoreHours: "Lütfen haftanın en az bir günü için çalışma saatlerinizi ayarlayın.",
            noImagesUploaded: "Resim Yüklenmedi",
            addImagesAttractCustomers: "Dükkanınızın fotoğraflarını eklemek daha fazla müşteri çekebilir. Resimsiz devam etmek ister misiniz?",
            continueWithoutImages: "Resimsiz devam et",
            addImages: "Resim ekleyeceğim",
            noServicesAdded: "Hizmet Eklenmedi",
            addAtLeastOneService: "Lütfen en az bir hizmet ekleyin ve hem adını hem de fiyatını belirtin.",
            shopCreatedSuccessfully: "Dükkan Başarıyla Oluşturuldu",
            shopCreatedShareLink: "Dükkanınız oluşturuldu! Bu linki müşterilerinizle paylaşın:",
            error: "Hata",
            creatingShop: "Dükkanınız oluşturuluyor...",
            errorCreatingShop: "Berber dükkanınızı oluştururken bir hata oluştu. Lütfen tekrar deneyin.",
            subscribeToCreate: "Berber dükkanı oluşturmak için lütfen abone olun.",
            subscribeNowButton: "Şimdi Abone Ol"
        },
        ar: {
            enterBiography: "صف صالون الحلاقة الخاص بك...",
            biographyHelp: "استخدم زر القالب للبدء بتخطيط احترافي",
            viewCreatedShops: "عرض المحلات المنشأة",
            createYourBarberShop: "أنشئ صالون الحلاقة الخاص بك",
            trialExpired: "انتهت الفترة التجريبية",
            trialExpiredMessage: "انتهت فترتك التجريبية. يرجى الاشتراك للاستمرار في استخدام خدماتنا.",
            trialActive: "الفترة التجريبية نشطة",
            trialActiveMessage: "أنت حاليًا في فترتك التجريبية لمدة 14 يومًا.",
            salon: "الصالون",
            availability: "التوفر",
            images: "الصور",
            services: "الخدمات",
            publish: "نشر",
            barberShopName: "اسم صالون الحلاقة",
            enterShopName: "أدخل اسم صالون الحلاقة الخاص بك",
            address: "العنوان",
            enterAddress: "أدخل عنوان صالونك",
            phoneNumber: "رقم الهاتف",
            enterPhoneNumber: "أدخل رقم هاتفك",
            email: "البريد الإلكتروني",
            enterEmail: "أدخل عنوان بريدك الإلكتروني",
            biography: "السيرة الذاتية",
            next: "التالي",
            back: "السابق",
            setYourAvailability: "حدد أوقات توفرك",
            dropzoneText: "اسحب وأفلت بعض الصور هنا، أو انقر لتحديد الملفات",
            quickAddServices: "إضافة خدمات سريعة",
            serviceName: "اسم الخدمة",
            price: "السعر",
            addService: "إضافة خدمة",
            creating: "جاري الإنشاء...",
            success: "تم بنجاح!",
            shopCreatedMessage: "تم إنشاء صالون الحلاقة الخاص بك. لتفعيل صالونك، يرجى الاشتراك في خدمتنا.",
            subscribeNow: "اشترك الآن - €25/شهريًا",
            uniqueLinkMessage: "سيتم إنشاء رابط صالونك الفريد بعد الاشتراك.",
            createAnotherShop: "إنشاء صالون آخر",
            missingInformation: "معلومات ناقصة",
            fillAllFields: "يرجى ملء جميع الحقول المطلوبة: اسم الصالون، العنوان، رقم الهاتف، البريد الإلكتروني، والسيرة الذاتية.",
            storeHoursRequired: "ساعات العمل مطلوبة",
            setStoreHours: "يرجى تحديد ساعات عملك ليوم واحد على الأقل من أيام الأسبوع.",
            noImagesUploaded: "لم يتم تحميل صور",
            addImagesAttractCustomers: "إضافة صور لصالونك يمكن أن يجذب المزيد من العملاء. هل ترغب في المتابعة بدون صور؟",
            continueWithoutImages: "المتابعة بدون صور",
            addImages: "سأضيف صورًا",
            noServicesAdded: "لم تتم إضافة خدمات",
            addAtLeastOneService: "يرجى إضافة خدمة واحدة على الأقل مع ذكر الاسم والسعر.",
            shopCreatedSuccessfully: "تم إنشاء الصالون بنجاح",
            shopCreatedShareLink: "تم إنشاء صالونك! شارك هذا الرابط مع عملائك:",
            error: "خطأ",
            creatingShop: "جاري إنشاء متجرك...",
            errorCreatingShop: "حدث خطأ أثناء إنشاء صالون الحلاقة الخاص بك. يرجى المحاولة مرة أخرى.",
            subscribeToCreate: "يرجى الاشتراك لإنشاء صالون حلاقة.",
            subscribeNowButton: "اشترك الآن"
        },
        de: {
            enterBiography: "Beschreiben Sie Ihren Friseursalon...",
            biographyHelp: "Verwenden Sie die Vorlagenschaltfläche, um mit einem professionellen Layout zu beginnen",
            viewCreatedShops: "Erstellte Läden anzeigen",
            createYourBarberShop: "Erstellen Sie Ihren Friseursalon",
            trialExpired: "Testphase abgelaufen",
            trialExpiredMessage: "Ihre Testphase ist abgelaufen. Bitte abonnieren Sie, um unsere Dienste weiterhin zu nutzen.",
            trialActive: "Testphase aktiv",
            trialActiveMessage: "Sie befinden sich derzeit in Ihrer 14-tägigen Testphase.",
            salon: "Salon",
            availability: "Verfügbarkeit",
            images: "Bilder",
            services: "Dienstleistungen",
            publish: "Veröffentlichen",
            barberShopName: "Name des Friseursalons",
            enterShopName: "Geben Sie den Namen Ihres Friseursalons ein",
            address: "Adresse",
            enterAddress: "Geben Sie die Adresse Ihres Salons ein",
            phoneNumber: "Telefonnummer",
            enterPhoneNumber: "Geben Sie Ihre Telefonnummer ein",
            email: "E-Mail",
            enterEmail: "Geben Sie Ihre E-Mail-Adresse ein",
            biography: "Biografie",
            next: "Weiter",
            back: "Zurück",
            setYourAvailability: "Legen Sie Ihre Verfügbarkeit fest",
            dropzoneText: "Ziehen Sie einige Bilder hierher oder klicken Sie, um Dateien auszuwählen",
            quickAddServices: "Schnelle Dienstleistungen hinzufügen",
            serviceName: "Name der Dienstleistung",
            price: "Preis",
            addService: "Dienstleistung hinzufügen",
            creating: "Wird erstellt...",
            success: "Erfolg!",
            shopCreatedMessage: "Ihr Friseursalon wurde erstellt. Um Ihren Salon zu aktivieren, abonnieren Sie bitte unseren Service.",
            subscribeNow: "Jetzt abonnieren - €25/Monat",
            uniqueLinkMessage: "Ihr einzigartiger Salon-Link wird nach dem Abonnement generiert.",
            createAnotherShop: "Einen weiteren Salon erstellen",
            missingInformation: "Fehlende Informationen",
            fillAllFields: "Bitte füllen Sie alle erforderlichen Felder aus: Salonname, Adresse, Telefonnummer, E-Mail und Biografie.",
            storeHoursRequired: "Öffnungszeiten erforderlich",
            setStoreHours: "Bitte legen Sie Ihre Öffnungszeiten für mindestens einen Tag der Woche fest.",
            noImagesUploaded: "Keine Bilder hochgeladen",
            addImagesAttractCustomers: "Das Hinzufügen von Fotos Ihres Salons kann mehr Kunden anziehen. Möchten Sie ohne Bilder fortfahren?",
            continueWithoutImages: "Ohne Bilder fortfahren",
            addImages: "Ich werde Bilder hinzufügen",
            noServicesAdded: "Keine Dienstleistungen hinzugefügt",
            addAtLeastOneService: "Bitte fügen Sie mindestens eine Dienstleistung mit Namen und Preis hinzu.",
            shopCreatedSuccessfully: "Salon erfolgreich erstellt",
            shopCreatedShareLink: "Ihr Salon wurde erstellt! Teilen Sie diesen Link mit Ihren Kunden:",
            error: "Fehler",
            creatingShop: "Ihr Laden wird erstellt...",
            errorCreatingShop: "Beim Erstellen Ihres Friseursalons ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
            subscribeToCreate: "Bitte abonnieren Sie, um einen Friseursalon zu erstellen.",
            subscribeNowButton: "Jetzt abonnieren"
        }
    };

    const t = translations[language];

    const editorConfig = {
        height: 500,
        menubar: true,
        // Include language packs and language_url
        language: language === 'tr' ? 'tr' :
            language === 'ar' ? 'ar' :
                language === 'de' ? 'de' : 'en',
        // language_url: language === 'tr' ? 'https://cdn.tiny.cloud/1/6eke8w2nyjpg9rotzvxhe9klva3y1xetkxmbp50pjy5klfjb/tinymce/6/langs/tr_TR.js' :
        //     language === 'ar' ? 'https://cdn.tiny.cloud/1/6eke8w2nyjpg9rotzvxhe9klva3y1xetkxmbp50pjy5klfjb/tinymce/6/langs/ar.js' :
        //         language === 'de' ? 'https://cdn.tiny.cloud/1/6eke8w2nyjpg9rotzvxhe9klva3y1xetkxmbp50pjy5klfjb/tinymce/6/langs/de.js' : '',
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'help', 'wordcount',
            'emoticons'
        ],
        toolbar: [
            'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent',
            'template | removeformat | help | emoticons'
        ].join(' | '),
        style_formats: [
            {
                title: 'Headers', items: [
                    {title: 'Header 1', format: 'h1'},
                    {title: 'Header 2', format: 'h2'},
                    {title: 'Header 3', format: 'h3'}
                ]
            },
            {
                title: 'Inline', items: [
                    {title: 'Bold', format: 'bold'},
                    {title: 'Italic', format: 'italic'},
                    {title: 'Underline', format: 'underline'}
                ]
            },
            {
                title: 'Blocks', items: [
                    {title: 'Paragraph', format: 'p'},
                    {title: 'Service List', format: 'div', classes: 'service-list'},
                    {title: 'Highlight Box', format: 'div', classes: 'highlight-box'}
                ]
            }
        ],
        templates: BARBERSHOP_TEMPLATES,
        content_style: CUSTOM_STYLES,
        placeholder: t.enterBiography || 'Describe your barbershop...',
        branding: false,
        promotion: false,
        directionality: language === 'ar' ? 'rtl' : 'ltr',
        paste_data_images: true,
        automatic_uploads: true,
        images_upload_handler: async (blobInfo) => {
            return new Promise((resolve) => {
                resolve('');
            });
        }
    };

    const LoadingScreen = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <span className="loading loading-bars loading-lg"></span>
                <p className="mt-4 text-lg font-semibold">{t.creatingShop}</p>
            </div>
        </div>
    );

    const calculatePricingTier = (services) => {
        const totalPricing = services.reduce((sum, service) =>
            sum + parseInt(service.price || 0, 10), 0);

        if (totalPricing <= 100) return '€';
        if (totalPricing <= 200) return '€€';
        return '€€€';
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-8">{t.createYourBarberShop}</h1>

            {trialStatus === 'expired' && (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                    <p className="font-bold">{t.trialExpired}</p>
                    <p>{t.trialExpiredMessage}</p>
                </div>
            )}

            {trialStatus === 'active' && (
                <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
                    <p className="font-bold">{t.trialActive}</p>
                    <p>{t.trialActiveMessage}</p>
                </div>
            )}

            <CreationSteps
                currentStep={currentStep}
                onStepClick={handleStepChange}
                isPublished={isPublished}
            />

            {isLoading && <LoadingScreen/>}

            <form onSubmit={handleSubmit} className="space-y-8">
                {currentStep === 1 && (
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="shopName" className="block text-sm font-medium text-gray-700">
                                {t.barberShopName}
                            </label>
                            <input
                                type="text"
                                id="shopName"
                                className="mt-1 block w-full input input-bordered"
                                value={shopName}
                                onChange={(e) => setShopName(e.target.value)}
                                required
                                placeholder={t.enterShopName}
                            />
                        </div>
                        <div className="relative">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                {t.address}
                            </label>
                            <div className="relative mt-1">
                                <input
                                    ref={addressInputRef}
                                    type="text"
                                    id="address"
                                    className="block w-full input input-bordered pr-10"
                                    value={address}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        setIsSuggestionSelected(false);
                                        setIsAddingCustomAddress(false);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (address.trim()) {
                                                setIsSuggestionSelected(true);
                                                setAddressSuggestions([]);
                                                setIsAddingCustomAddress(false);
                                                // Move focus to next input
                                                const form = e.target.form;
                                                const index = Array.prototype.indexOf.call(form, e.target);
                                                form.elements[index + 1]?.focus();
                                            }
                                        } else if (e.key === 'Escape') {
                                            setAddressSuggestions([]);
                                            setIsAddingCustomAddress(false);
                                        }
                                    }}
                                    required
                                    placeholder={t.enterAddress}
                                />
                                {address && !isSuggestionSelected && !isAddingCustomAddress && (
                                    <button
                                        type="button"
                                        className="absolute right-2 top-1/2 -translate-y-1/2 text-primary-content hover:text-primary transition-colors"
                                        onClick={() => {
                                            setIsSuggestionSelected(true);
                                            setAddressSuggestions([]);
                                        }}
                                    >
                                        <Check className="w-5 h-5"/>
                                    </button>
                                )}
                            </div>

                            {/* Suggestions Dropdown */}
                            {(addressSuggestions.length > 0 || (address.length > 3 && !isSuggestionSelected)) && (
                                <div
                                    className="absolute z-[2001] w-full bg-base-100 border border-base-300 mt-1 rounded-lg shadow-xl overflow-hidden">
                                    {addressSuggestions.map((suggestion, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className="w-full px-4 py-2 text-left hover:bg-base-200 transition-colors duration-150
                             flex items-center justify-between group"
                                            onClick={() => {
                                                setAddress(suggestion);
                                                setIsSuggestionSelected(true);
                                                setAddressSuggestions([]);
                                                setIsAddingCustomAddress(false);
                                            }}
                                        >
                                            <span className="truncate">{suggestion}</span>
                                            <Check
                                                className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity"/>
                                        </button>
                                    ))}

                                    {/* "Use Custom Address" Option */}
                                    {!isAddingCustomAddress && address.length > 3 && (
                                        <>
                                            {addressSuggestions.length > 0 && (
                                                <div className="border-t border-base-300"/>
                                            )}
                                            <button
                                                type="button"
                                                className="w-full px-4 py-2 text-left hover:bg-base-200 transition-colors duration-150
                                 flex items-center justify-between group text-primary"
                                                onClick={() => {
                                                    setIsSuggestionSelected(true);
                                                    setAddressSuggestions([]);
                                                    setIsAddingCustomAddress(false);
                                                    addressInputRef.current?.blur();
                                                }}
                                            >
                        <span className="flex items-center gap-2">
                            <Store className="w-4 h-4"/>
                            {language === 'tr' ? 'Bu adresi kullan' :
                                language === 'ar' ? 'استخدم هذا العنوان' :
                                    language === 'de' ? 'Diese Adresse verwenden' :
                                        'Use this address'}: "{address}"
                        </span>
                                                <Check
                                                    className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity"/>
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}

                            {/* Mobile Hint */}
                            {!isSuggestionSelected && address.length > 3 && (
                                <div className="mt-1 text-sm text-base-content/70 flex items-center gap-1">
                                    <Info className="w-4 h-4"/>
                                    {language === 'tr' ? 'Adresinizi seçin veya özel adres girin' :
                                        language === 'ar' ? 'اختر عنوانك أو أدخل عنوانًا مخصصًا' :
                                            language === 'de' ? 'Wählen Sie Ihre Adresse aus oder geben Sie eine benutzerdefinierte Adresse ein' :
                                                'Select your address or enter a custom one'}
                                </div>
                            )}

                            {isLoadingSuggestions && (
                                <div
                                    className="absolute z-[2001] w-full bg-base-100 border border-base-300 mt-1 rounded-lg shadow-xl p-4 flex items-center justify-center">
                                    <span className="loading loading-spinner loading-sm mr-2"></span>
                                    {language === 'tr' ? 'Adresler yükleniyor...' :
                                        language === 'ar' ? 'جارٍ تحميل العناوين...' :
                                            language === 'de' ? 'Adressen werden geladen...' :
                                                'Loading addresses...'}
                                </div>
                            )}
                        </div>
                        <div className="relative mt-4 mb-4">
                            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                                {t.phoneNumber}
                            </label>
                            <div
                                className="relative z-[100]"> {/* Add z-index to ensure dropdown appears above other elements */}
                                <PhoneInput
                                    country={'tr'}
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    inputStyle={phoneInputStyle}
                                    containerStyle={{
                                        width: '100%',
                                        marginTop: '0.25rem'
                                    }}
                                    dropdownStyle={{
                                        width: '300px', // Fixed width for dropdown
                                        maxHeight: '200px',
                                        zIndex: 100
                                    }}
                                    buttonStyle={{
                                        border: '1px solid rgb(209, 213, 219)',
                                        borderRight: 'none',
                                        borderRadius: '0.375rem 0 0 0.375rem',
                                        backgroundColor: 'white'
                                    }}
                                    inputProps={{
                                        className: 'input input-bordered',
                                        style: {
                                            paddingLeft: '52px', // Match the paddingLeft in phoneInputStyle
                                            width: '100%'
                                        }
                                    }}
                                    inputClass="!w-full !h-10 !py-2 !px-3 !border-gray-300 focus:!border-primary"
                                    enableSearch={true}
                                    searchPlaceholder="Search countries"
                                    placeholder={t.enterPhoneNumber}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t.email}
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="mt-1 block w-full input input-bordered"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder={t.enterEmail}
                            />
                        </div>

                        <div>
                            <ShopCategorySelector
                                value={categories}
                                onChange={setCategories}
                                error={!categories.length && formTouched ? 'Please select at least one category' : null}
                            />
                        </div>

                        <div>
                            <label htmlFor="biography" className="block text-sm font-medium text-gray-700">
                                {t.biography}
                            </label>
                            <div className="mt-1">
                                {/*<Editor*/}
                                {/*    key={editorKey}*/}
                                {/*    apiKey='6eke8w2nyjpg9rotzvxhe9klva3y1xetkxmbp50pjy5klfjb'*/}
                                {/*    onInit={(_evt, editor) => editorRef.current = editor}*/}
                                {/*    initialValue=""*/}
                                {/*    init={editorConfig}*/}
                                {/*    onEditorChange={(content) => {*/}
                                {/*        console.log('Content changed:', content);*/}
                                {/*        setEditorContent(content); // Add this line*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <BarbershopEditor
                                    language={language}
                                    value={editorContent}
                                    onChange={setEditorContent}
                                    trialStatus={trialStatus}
                                    user={user}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                {t.biographyHelp || 'Use the template button to start with a professional layout'}
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleStepChange(currentStep + 1)}
                        >
                            {t.next}
                        </button>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="space-y-4">
                        <EnhancedAvailabilitySelector
                            availability={availability}
                            setAvailability={setAvailability}
                            specialDates={specialDates}
                            setSpecialDates={setSpecialDates}
                            t={t}
                        />
                        <div className="flex justify-between">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => handleStepChange(currentStep - 1)}
                            >
                                {t.back}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleStepChange(3)}
                            >
                                {t.next}
                            </button>
                        </div>
                    </div>
                )}

                {
                    currentStep === 3 && (
                        <div className="space-y-4">
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <p className="text-center p-20 border-2 border-dashed border-gray-300 rounded-lg">
                                    {t.dropzoneText}
                                </p>
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
                                {images.map((file, index) => (
                                    <div key={file.name} className="relative">
                                        <img
                                            src={file.preview}
                                            alt={`${t.imagePreview} ${index + 1}`}
                                            className="w-full h-40 object-cover rounded-lg"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                const newImages = [...images];
                                                newImages.splice(index, 1);
                                                setImages(newImages);
                                            }}
                                            className="absolute top-1 right-1 bg-white bg-opacity-70 text-gray-700 rounded-full w-6 h-6 flex items-center justify-center shadow-md hover:bg-opacity-100 transition-colors"
                                            aria-label={t.removeImage}
                                        >
                                            <span className="text-xl font-bold">×</span>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => handleStepChange(2)}
                                >
                                    {t.back}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleStepChange(4)}
                                >
                                    {t.next}
                                </button>
                            </div>
                        </div>
                    )
                }

                {
                    currentStep === 4 && (
                        <div className="space-y-4">
                            <h2 className="text-xl font-semibold">{t.services}</h2>
                            <div className="bg-base-200 p-4 rounded-lg">
                                <h3 className="text-lg font-medium mb-2">{t.quickAddServices}</h3>
                                <PresetServiceSelector
                                    onServiceSelect={handlePresetServiceSelect}
                                    selectedServices={services}  // Pass your current services
                                />
                            </div>
                            {services.map((service, index) => (
                                <div key={index} className="flex flex-col sm:flex-row gap-2 sm:items-center">
                                    <input
                                        type="text"
                                        className="input input-bordered w-full"
                                        placeholder={t.serviceName}
                                        value={service.name}
                                        onChange={(e) => handleServiceChange(index, 'name', e.target.value)}
                                    />
                                    <div className="flex gap-2">
                                        <div className="relative flex-1 sm:flex-none">
                                            <input
                                                type="text"
                                                className="input input-bordered w-full sm:w-24 pl-6"
                                                placeholder={t.price}
                                                value={service.price}
                                                onChange={(e) => handleServiceChange(index, 'price', e.target.value)}
                                            />
                                            <span className="absolute left-2 top-1/2 transform -translate-y-1/2">€</span>
                                        </div>
                                        <div className="relative flex-1 sm:flex-none">
                                            <input
                                                type="text"
                                                className="input input-bordered w-full sm:w-24 pr-12"
                                                placeholder="Duration"
                                                value={service.duration || ''}
                                                onChange={(e) => handleServiceChange(index, 'duration', e.target.value)}
                                            />
                                            <span
                                                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">min</span>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => removeService(index)}
                                            className="btn btn-circle btn-sm"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button type="button" onClick={addService} className="btn btn-secondary btn-sm">
                                {t.addService}
                            </button>
                            <div className="flex justify-between mt-4">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => handleStepChange(3)}
                                >
                                    {t.back}
                                </button>
                                <button
                                    type="button"  // Changed from "submit" to "button"
                                    className="btn btn-primary"
                                    onClick={() => handleStepChange(5)}  // Changed to directly go to step 5
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? t.creating : t.next}
                                </button>
                            </div>
                        </div>
                    )
                }

                {currentStep === 5 && (
                    <EmployeeManagementStep
                        shopId={tempShopId}
                        shopData={shopData}
                        onBack={() => handleStepChange(4)}
                        onNext={(teamData) => {
                            handleStepChange(6);
                        }}
                        language={language}
                    />
                )}

                {currentStep === 6 && (
                    <GoogleBusinessStep
                        onBack={() => handleStepChange(4)}
                        onNext={handleGoogleBusinessCreation}
                        tempShopId={tempShopId}
                        shopData={{
                            name: shopName,
                            address: address,
                            phoneNumber: phoneNumber,
                            email: email,
                            description: editorContent,
                            services: services,
                            availability: availability,
                            images: images,
                            specialDates: specialDates,
                            categories: categories,
                            pricingTier: calculatePricingTier(services),
                        }}
                    />
                )}

                {currentStep === 7 && (
                    <div className="text-center space-y-4">
                        <h2 className="text-2xl font-bold text-success">{t.success}</h2>
                        <p>{t.shopCreatedMessage}</p>
                        <button className="btn btn-primary">
                            {t.subscribeNow}
                        </button>
                        <p className="text-sm text-gray-500">
                            {t.uniqueLinkMessage}
                        </p>
                        <button
                            className="btn btn-secondary mt-4"
                            onClick={() => navigate('/account')}
                        >
                            {t.viewCreatedShops}
                        </button>
                        <br/>
                        <button
                            className="btn btn-secondary mt-4"
                            onClick={() => {
                                resetForm();
                                navigate('/create-shop');
                            }}
                        >
                            {t.createAnotherShop}
                        </button>
                    </div>
                )}
            </form>
        </div>
    )
        ;
};

export default CreateBarberShop;