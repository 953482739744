import React, {useContext, useRef, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useDropzone} from 'react-dropzone';
import PhoneInput from 'react-phone-input-2';
import {doc, serverTimestamp, updateDoc} from 'firebase/firestore';
import {deleteObject, getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {db, storage} from '../firebase';
import {Camera, Check, Clock, Image, Scissors, Store, TagIcon, Trash2, Users2, X} from 'lucide-react';
import EnhancedAvailabilitySelector from './EnhancedAvailabilitySelector';
import PresetServiceSelector from './PresetServiceSelector';
import LanguageContext from './LanguageContext';
import ShopCategorySelector from "./ShopCategorySelector";
import {nanoid} from "nanoid";
import WeeklyScheduleSelector from "./WeeklyScheduleSelector";

const EditBarberShopModal = ({shop, isOpen, onClose, onSave}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [shopData, setShopData] = useState({
        name: shop.name,
        address: shop.address,
        phoneNumber: shop.phoneNumber,
        email: shop.email,
        description: shop.biography,
        services: shop.services,
        availability: shop.availability,
        imageUrls: shop.imageUrls,
        categories: shop.categories || [],
        employees: shop.employees || []
    });
    const [images, setImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const editorRef = useRef(null);
    const {language} = useContext(LanguageContext);


    const [newEmployee, setNewEmployee] = useState({
        name: '',
        photo: null,
        expertise: [],
        schedule: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: []
        }
    });

    // Add translations object
    const translations = {
        en: {
            setYourAvailability: "Set Your Availability",
            next: "Next",
            previous: "Previous",
            saving: "Saving...",
            saveChanges: "Save Changes",
            dropImagesHere: "Drop images here or click to select",
            serviceName: "Service name",
            price: "Price",
            quickAddServices: "Quick Add Services",
            addService: "Add Service",
            categories: "Categories",
            selectCategories: "Select Categories",
            categoriesRequired: "Please select at least one category"
        },
        tr: {
            setYourAvailability: "Müsaitlik Durumunuzu Ayarlayın",
            next: "İleri",
            previous: "Geri",
            saving: "Kaydediliyor...",
            saveChanges: "Değişiklikleri Kaydet",
            dropImagesHere: "Resimleri buraya sürükleyip bırakın veya dosya seçmek için tıklayın",
            serviceName: "Hizmet adı",
            price: "Fiyat",
            quickAddServices: "Hızlı Hizmet Ekle",
            addService: "Hizmet Ekle",
            categories: "Kategoriler",
            selectCategories: "Kategori Seçin",
            categoriesRequired: "Lütfen en az bir kategori seçin"
        },
        ar: {
            setYourAvailability: "حدد أوقات توفرك",
            next: "التالي",
            previous: "السابق",
            saving: "جاري الحفظ...",
            saveChanges: "حفظ التغييرات",
            dropImagesHere: "اسحب الصور هنا أو انقر للتحديد",
            serviceName: "اسم الخدمة",
            price: "السعر",
            quickAddServices: "إضافة خدمات سريعة",
            addService: "إضافة خدمة",
            categories: "الفئات",
            selectCategories: "اختر الفئات",
            categoriesRequired: "الرجاء اختيار فئة واحدة على الأقل"
        },
        de: {
            setYourAvailability: "Verfügbarkeit festlegen",
            next: "Weiter",
            previous: "Zurück",
            saving: "Wird gespeichert...",
            saveChanges: "Änderungen speichern",
            dropImagesHere: "Bilder hier ablegen oder klicken zum Auswählen",
            serviceName: "Name der Dienstleistung",
            price: "Preis",
            quickAddServices: "Schnelle Dienstleistungen hinzufügen",
            addService: "Dienstleistung hinzufügen",
            categories: "Kategorien",
            selectCategories: "Kategorien auswählen",
            categoriesRequired: "Bitte wählen Sie mindestens eine Kategorie"
        }
    };

    const t = translations[language];


    const steps = [
        {id: 1, title: 'Salon', icon: Store},
        {id: 2, title: 'Availability', icon: Clock},
        {id: 3, title: 'Images', icon: Image},
        {id: 4, title: 'Categories', icon: TagIcon},
        {id: 5, title: 'Services', icon: Scissors},
        {id: 6, title: 'Team', icon: Users2}
    ];

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setImages([...images, ...acceptedFiles.map(file => ({
                file,
                preview: URL.createObjectURL(file)
            }))]);
        }
    });

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Handle image uploads and deletions
            const imagePromises = [];
            const newImageUrls = [...shopData.imageUrls];

            // Delete removed images
            for (const url of deletedImages) {
                const imageRef = ref(storage, url);
                imagePromises.push(deleteObject(imageRef));
                const index = newImageUrls.indexOf(url);
                if (index > -1) {
                    newImageUrls.splice(index, 1);
                }
            }

            // Upload new images
            for (const image of images) {
                const imageRef = ref(storage, `shops/${shop.ownerId}/${image.file.name}`);
                const uploadPromise = uploadBytes(imageRef)
                    .then(() => getDownloadURL(imageRef))
                    .then(url => newImageUrls.push(url));
                imagePromises.push(uploadPromise);
            }

            await Promise.all(imagePromises);

            // Update Firestore
            const shopRef = doc(db, 'barberShops', shop.id);
            await updateDoc(shopRef, {
                name: shopData.name,
                address: shopData.address,
                phoneNumber: shopData.phoneNumber,
                email: shopData.email,
                biography: editorRef.current.getContent(),
                services: shopData.services,
                availability: shopData.availability,
                imageUrls: newImageUrls,
                categories: shopData.categories,
                employees: shopData.employees,
                lastUpdated: serverTimestamp()
            });

            onSave({
                ...shopData,
                id: shop.id,
                imageUrls: newImageUrls
            });
            onClose();
        } catch (error) {
            console.error('Error updating shop:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="space-y-4">
                        <div>
                            <label className="label">Shop Name</label>
                            <input
                                type="text"
                                className="input input-bordered w-full"
                                value={shopData.name}
                                onChange={(e) => setShopData({...shopData, name: e.target.value})}
                            />
                        </div>
                        <div>
                            <label className="label">Address</label>
                            <input
                                type="text"
                                className="input input-bordered w-full"
                                value={shopData.address}
                                onChange={(e) => setShopData({...shopData, address: e.target.value})}
                            />
                        </div>
                        <div>
                            <label className="label">Phone Number</label>
                            <PhoneInput
                                country="tr"
                                value={shopData.phoneNumber}
                                onChange={(value) => setShopData({...shopData, phoneNumber: value})}
                                inputClass="input input-bordered w-full"
                            />
                        </div>
                        <div>
                            <label className="label">Email</label>
                            <input
                                type="email"
                                className="input input-bordered w-full"
                                value={shopData.email}
                                onChange={(e) => setShopData({...shopData, email: e.target.value})}
                            />
                        </div>
                        <div>
                            <label className="label">Description</label>
                            <Editor
                                apiKey='6eke8w2nyjpg9rotzvxhe9klva3y1xetkxmbp50pjy5klfjb'
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={shopData.description}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                }}
                            />
                        </div>
                    </div>
                );

            case 2:
                return (
                    <EnhancedAvailabilitySelector
                        availability={shopData.availability}
                        setAvailability={(newAvailability) =>
                            setShopData({...shopData, availability: newAvailability})}
                        t={t}  // Pass the translations
                    />
                );

            case 3:
                return (
                    <div className="space-y-4">
                        <div {...getRootProps()} className="border-2 border-dashed p-8 text-center rounded-lg">
                            <input {...getInputProps()} />
                            <p>Drop images here or click to select</p>
                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                            {/* Existing Images */}
                            {shopData.imageUrls.map((url, index) => (
                                <div key={`existing-${index}`} className="relative">
                                    <img
                                        src={url}
                                        alt={`Shop ${index + 1}`}
                                        className="w-full h-32 object-cover rounded-lg"
                                    />
                                    <button
                                        onClick={() => {
                                            setDeletedImages([...deletedImages, url]);
                                            setShopData({
                                                ...shopData,
                                                imageUrls: shopData.imageUrls.filter(u => u !== url)
                                            });
                                        }}
                                        className="absolute top-2 right-2 btn btn-circle btn-error btn-sm"
                                    >
                                        <X className="w-4 h-4"/>
                                    </button>
                                </div>
                            ))}

                            {/* New Images */}
                            {images.map((image, index) => (
                                <div key={`new-${index}`} className="relative">
                                    <img
                                        src={image.preview}
                                        alt={`New ${index + 1}`}
                                        className="w-full h-32 object-cover rounded-lg"
                                    />
                                    <button
                                        onClick={() => {
                                            setImages(images.filter((_, i) => i !== index));
                                        }}
                                        className="absolute top-2 right-2 btn btn-circle btn-error btn-sm"
                                    >
                                        <X className="w-4 h-4"/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                );

            case 4:
                return (
                    <ShopCategorySelector
                        value={shopData.categories}
                        onChange={(newCategories) => {
                            setShopData({
                                ...shopData,
                                categories: newCategories
                            });
                        }}
                        error={null}
                    />
                );

            case 5:
                return (
                    <div className="space-y-4">
                        <div className="bg-base-200 p-4 rounded-lg">
                            <h3 className="font-medium mb-2">Quick Add Services</h3>
                            <PresetServiceSelector
                                onServiceSelect={(service) => {
                                    if (!shopData.services.some(s =>
                                        s.name === service.name && s.price === service.price
                                    )) {
                                        setShopData({
                                            ...shopData,
                                            services: [...shopData.services, service]
                                        });
                                    }
                                }}
                            />
                        </div>

                        {shopData.services.map((service, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <input
                                    type="text"
                                    className="input input-bordered flex-grow"
                                    value={service.name}
                                    onChange={(e) => {
                                        const newServices = [...shopData.services];
                                        newServices[index].name = e.target.value;
                                        setShopData({...shopData, services: newServices});
                                    }}
                                    placeholder="Service name"
                                />
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="input input-bordered w-24 pl-6"
                                        value={service.price}
                                        onChange={(e) => {
                                            const newServices = [...shopData.services];
                                            newServices[index].price = e.target.value.replace(/[^0-9]/g, '');
                                            setShopData({...shopData, services: newServices});
                                        }}
                                        placeholder="Price"
                                    />
                                    <span className="absolute left-2 top-1/2 -translate-y-1/2">€</span>
                                </div>
                                <button
                                    onClick={() => {
                                        const newServices = shopData.services.filter((_, i) => i !== index);
                                        setShopData({...shopData, services: newServices});
                                    }}
                                    className="btn btn-circle btn-sm"
                                >
                                    <X className="w-4 h-4"/>
                                </button>
                            </div>
                        ))}

                        <button
                            onClick={() => setShopData({
                                ...shopData,
                                services: [...shopData.services, {name: '', price: ''}]
                            })}
                            className="btn btn-secondary btn-sm"
                        >
                            Add Service
                        </button>
                    </div>
                );

            case 6:
                return (
                    <div className="space-y-6">
                        {/* Current Employees List */}
                        {shopData.employees?.length > 0 && (
                            <div className="bg-base-200 p-4 rounded-lg">
                                <h3 className="font-medium mb-4">Current Team Members</h3>
                                <div className="space-y-4">
                                    {shopData.employees.map((employee, index) => (
                                        <div key={employee.id}
                                             className="flex items-center gap-4 p-3 bg-base-100 rounded-lg shadow group">
                                            <div className="avatar">
                                                <div className="w-16 h-16 rounded-full">
                                                    <img
                                                        src={employee.photo || `https://api.dicebear.com/7.x/avataaars/svg?seed=${employee.name}`}
                                                        alt={employee.name}
                                                        className="object-cover"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="font-semibold text-lg">{employee.name}</h4>
                                                <div className="flex flex-wrap gap-1 mt-1">
                                                    {employee.expertise?.map((skill, idx) => (
                                                        <span key={idx} className="badge badge-sm">{skill}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    const updatedEmployees = shopData.employees.filter(
                                                        (_, i) => i !== index
                                                    );
                                                    setShopData({
                                                        ...shopData,
                                                        employees: updatedEmployees
                                                    });
                                                }}
                                                className="btn btn-ghost btn-sm text-error opacity-0 group-hover:opacity-100 transition-opacity"
                                            >
                                                <Trash2 className="w-4 h-4"/>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Add New Employee Form */}
                        <div className="card bg-base-200">
                            <div className="card-body">
                                <h3 className="card-title">Add New Team Member</h3>

                                {/* Name Input */}
                                <div className="form-control">
                                    <label className="label">Name</label>
                                    <input
                                        type="text"
                                        className="input input-bordered"
                                        value={newEmployee.name}
                                        onChange={(e) => setNewEmployee({
                                            ...newEmployee,
                                            name: e.target.value
                                        })}
                                    />
                                </div>

                                {/* Photo Upload */}
                                <div className="form-control">
                                    <label className="label">Photo</label>
                                    <div className="border-2 border-dashed rounded-lg p-4 text-center">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    setNewEmployee({
                                                        ...newEmployee,
                                                        photo: e.target.files[0]
                                                    });
                                                }
                                            }}
                                            className="hidden"
                                            id="employee-photo"
                                        />
                                        <label htmlFor="employee-photo" className="cursor-pointer">
                                            {newEmployee.photo ? (
                                                <div className="relative inline-block">
                                                    <img
                                                        src={URL.createObjectURL(newEmployee.photo)}
                                                        alt="Preview"
                                                        className="w-32 h-32 rounded-lg object-cover"
                                                    />
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setNewEmployee({
                                                                ...newEmployee,
                                                                photo: null
                                                            });
                                                        }}
                                                        className="absolute -top-2 -right-2 btn btn-error btn-circle btn-xs"
                                                    >
                                                        <X className="w-3 h-3"/>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="text-base-content/50">
                                                    <Camera className="w-10 h-10 mx-auto mb-2"/>
                                                    <p>Click to upload photo</p>
                                                </div>
                                            )}
                                        </label>
                                    </div>
                                </div>

                                {/* Expertise Tags */}
                                <div className="form-control">
                                    <label className="label">Expertise</label>
                                    <div className="flex flex-wrap gap-2">
                                        {['Haircut', 'Beard Trim', 'Hair Coloring', 'Styling',
                                            'Shaving', 'Hair Treatment', 'Kids Haircut', 'Facial'].map(tag => (
                                            <label key={tag} className="cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    className="hidden"
                                                    checked={newEmployee.expertise.includes(tag)}
                                                    onChange={(e) => {
                                                        const updatedExpertise = e.target.checked
                                                            ? [...newEmployee.expertise, tag]
                                                            : newEmployee.expertise.filter(t => t !== tag);
                                                        setNewEmployee({
                                                            ...newEmployee,
                                                            expertise: updatedExpertise
                                                        });
                                                    }}
                                                />
                                                <span className={`badge badge-lg ${
                                                    newEmployee.expertise.includes(tag)
                                                        ? 'badge-primary'
                                                        : 'badge-outline'
                                                }`}>
                                        {tag}
                                    </span>
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                {/* Schedule */}
                                <div className="form-control">
                                    <label className="label">Working Hours</label>
                                    <WeeklyScheduleSelector
                                        schedule={newEmployee.schedule}
                                        onScheduleChange={(newSchedule) =>
                                            setNewEmployee({
                                                ...newEmployee,
                                                schedule: newSchedule
                                            })
                                        }
                                        language={language}
                                    />
                                </div>

                                {/* Add Employee Button */}
                                <button
                                    onClick={async () => {
                                        if (!newEmployee.name || !newEmployee.photo ||
                                            newEmployee.expertise.length === 0) {
                                            // Show error message
                                            return;
                                        }

                                        try {
                                            // Upload photo first
                                            const photoRef = ref(storage,
                                                `shops/${shop.id}/employees/${newEmployee.name}-${nanoid(6)}`);
                                            await uploadBytes(photoRef, newEmployee.photo);
                                            const photoUrl = await getDownloadURL(photoRef);

                                            // Create employee object
                                            const employeeData = {
                                                id: nanoid(),
                                                name: newEmployee.name,
                                                photo: photoUrl,
                                                expertise: newEmployee.expertise,
                                                schedule: newEmployee.schedule
                                            };

                                            // Add to shopData state
                                            const updatedEmployees = [...shopData.employees, employeeData];
                                            setShopData({
                                                ...shopData,
                                                employees: updatedEmployees
                                            });

                                            // Reset new employee form
                                            setNewEmployee({
                                                name: '',
                                                photo: null,
                                                expertise: [],
                                                schedule: {
                                                    Monday: [],
                                                    Tuesday: [],
                                                    Wednesday: [],
                                                    Thursday: [],
                                                    Friday: [],
                                                    Saturday: [],
                                                    Sunday: []
                                                }
                                            });
                                        } catch (error) {
                                            console.error('Error adding employee:', error);
                                            // Show error message
                                        }
                                    }}
                                    className="btn btn-primary mt-4"
                                >
                                    Add Team Member
                                </button>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
            <div className="bg-base-100 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Edit {shop.name}</h2>
                    <button onClick={onClose} className="btn btn-ghost btn-circle">
                        <X className="w-6 h-6"/>
                    </button>
                </div>

                {/* Progress Steps */}
                <div className="flex justify-between mb-8">
                    {steps.map((step) => (
                        <button
                            key={step.id}
                            onClick={() => setCurrentStep(step.id)}
                            className={`flex flex-col items-center ${
                                currentStep === step.id
                                    ? 'text-primary'
                                    : currentStep > step.id
                                        ? 'text-success'
                                        : 'text-base-content/50'
                            }`}
                        >
                            <div className={`w-12 h-12 rounded-full flex items-center justify-center mb-2 ${
                                currentStep === step.id
                                    ? 'bg-primary text-primary-content'
                                    : currentStep > step.id
                                        ? 'bg-success text-success-content'
                                        : 'bg-base-300'
                            }`}>
                                {currentStep > step.id ? <Check className="w-6 h-6"/> :
                                    <step.icon className="w-6 h-6"/>}
                            </div>
                            <span className="text-sm font-medium">{step.title}</span>
                        </button>
                    ))}
                </div>

                {/* Step Content */}
                <div className="mb-8">
                    {renderStepContent()}
                </div>

                {/* Navigation */}
                <div className="flex justify-between mt-8">
                    <button
                        onClick={() => setCurrentStep(Math.max(1, currentStep - 1))}
                        className="btn btn-ghost"
                        disabled={currentStep === 1}
                    >
                        {t.previous}
                    </button>

                    {currentStep < steps.length ? (
                        <button
                            onClick={() => setCurrentStep(currentStep + 1)}
                            className="btn btn-primary"
                        >
                            {t.next}
                        </button>
                    ) : (
                        <button
                            onClick={handleSave}
                            className="btn btn-primary"
                            disabled={isLoading}
                        >
                            {isLoading ? t.saving : t.saveChanges}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditBarberShopModal;