import React, {useContext, useState} from 'react';
import {CheckCircle, TagIcon} from 'lucide-react';
import {AnimatePresence, motion} from 'framer-motion';
import LanguageContext from './LanguageContext';

const SHOP_CATEGORIES = [
    {
        id: 'traditional',
        icon: '💈',
        translations: {
            en: {
                label: 'Traditional Barbering',
                description: 'Classic cuts and traditional barbering services'
            },
            tr: {
                label: 'Geleneksel Berberlik',
                description: 'Klasik kesimler ve geleneksel berberlik hizmetleri'
            },
            ar: {
                label: 'الحلاقة التقليدية',
                description: 'قصات كلاسيكية وخدمات حلاقة تقليدية'
            },
            de: {
                label: 'Traditionelles Barbering',
                description: 'Klassische Schnitte und traditionelle Barbier-Dienstleistungen'
            }
        }
    },
    {
        id: 'african',
        icon: '✨',
        translations: {
            en: {
                label: 'African & Textured Hair',
                description: 'Specialized in African, Caribbean & textured hair types'
            },
            tr: {
                label: 'Afrika & Dokulu Saç',
                description: 'Afrika, Karayip ve dokulu saç tipleri konusunda uzmanlaşmış'
            },
            ar: {
                label: 'الشعر الأفريقي والمجعد',
                description: 'متخصصون في أنواع الشعر الأفريقي والكاريبي والمجعد'
            },
            de: {
                label: 'Afrikanisches & strukturiertes Haar',
                description: 'Spezialisiert auf afrikanische, karibische & strukturierte Haartypen'
            }
        }
    },
    {
        id: 'kids',
        icon: '👶',
        translations: {
            en: {
                label: 'Kids Specialist',
                description: 'Child-friendly environment and specialized kids services'
            },
            tr: {
                label: 'Çocuk Uzmanı',
                description: 'Çocuk dostu ortam ve özel çocuk hizmetleri'
            },
            ar: {
                label: 'متخصص أطفال',
                description: 'بيئة صديقة للأطفال وخدمات متخصصة للأطفال'
            },
            de: {
                label: 'Kinderspezialist',
                description: 'Kinderfreundliche Umgebung und spezialisierte Kinderdienstleistungen'
            }
        }
    },
    {
        id: 'women',
        icon: '💁‍♀️',
        translations: {
            en: {
                label: 'Women\'s Services',
                description: 'Specialized in women\'s cuts and styling'
            },
            tr: {
                label: 'Kadın Hizmetleri',
                description: 'Kadın kesimi ve şekillendirmede uzmanlaşmış'
            },
            ar: {
                label: 'خدمات نسائية',
                description: 'متخصصون في قصات وتصفيف الشعر للنساء'
            },
            de: {
                label: 'Damenfriseur',
                description: 'Spezialisiert auf Damenhaarschnitte und Styling'
            }
        }
    },
    {
        id: 'luxury',
        icon: '👑',
        translations: {
            en: {
                label: 'Luxury Experience',
                description: 'Premium services and VIP treatment'
            },
            tr: {
                label: 'Lüks Deneyim',
                description: 'Premium hizmetler ve VIP muamele'
            },
            ar: {
                label: 'تجربة فاخرة',
                description: 'خدمات متميزة ومعاملة VIP'
            },
            de: {
                label: 'Luxus-Erlebnis',
                description: 'Premium-Services und VIP-Behandlung'
            }
        }
    },
    {
        id: 'modern',
        icon: '🌟',
        translations: {
            en: {
                label: 'Modern & Trendy',
                description: 'Contemporary styles and latest trends'
            },
            tr: {
                label: 'Modern & Trend',
                description: 'Çağdaş stiller ve en son trendler'
            },
            ar: {
                label: 'عصري وحديث',
                description: 'تسريحات معاصرة وأحدث الصيحات'
            },
            de: {
                label: 'Modern & Trendig',
                description: 'Zeitgemäße Stile und neueste Trends'
            }
        }
    },
    {
        id: 'beard',
        icon: '🧔',
        translations: {
            en: {
                label: 'Beard Specialist',
                description: 'Expert beard grooming and styling'
            },
            tr: {
                label: 'Sakal Uzmanı',
                description: 'Uzman sakal bakımı ve şekillendirme'
            },
            ar: {
                label: 'متخصص لحى',
                description: 'تشذيب وتصفيف اللحى باحترافية'
            },
            de: {
                label: 'Bart-Spezialist',
                description: 'Experte für Bartpflege und Styling'
            }
        }
    },
    {
        id: 'unisex',
        icon: '✂️',
        translations: {
            en: {
                label: 'Unisex Salon',
                description: 'Services for all genders'
            },
            tr: {
                label: 'Unisex Salon',
                description: 'Tüm cinsiyetlere yönelik hizmetler'
            },
            ar: {
                label: 'صالون للجنسين',
                description: 'خدمات لجميع الأجناس'
            },
            de: {
                label: 'Unisex-Salon',
                description: 'Dienstleistungen für alle Geschlechter'
            }
        }
    }
];

const translations = {
    en: {
        title: 'Shop Categories',
        selectHint: 'Select at least one category',
        showMore: 'Show More',
        showLess: 'Show Less',
        required: 'Please select at least one category'
    },
    tr: {
        title: 'Dükkan Kategorileri',
        selectHint: 'En az bir kategori seçin',
        showMore: 'Daha Fazla Göster',
        showLess: 'Daha Az Göster',
        required: 'Lütfen en az bir kategori seçin'
    },
    ar: {
        title: 'فئات المحل',
        selectHint: 'اختر فئة واحدة على الأقل',
        showMore: 'عرض المزيد',
        showLess: 'عرض أقل',
        required: 'الرجاء اختيار فئة واحدة على الأقل'
    },
    de: {
        title: 'Geschäftskategorien',
        selectHint: 'Wählen Sie mindestens eine Kategorie',
        showMore: 'Mehr Anzeigen',
        showLess: 'Weniger Anzeigen',
        required: 'Bitte wählen Sie mindestens eine Kategorie'
    }
};

const ShopCategorySelector = ({value = [], onChange, error}) => {
    const {language} = useContext(LanguageContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const t = translations[language];

    const handleSelect = (categoryId) => {
        const newValue = value.includes(categoryId)
            ? value.filter(id => id !== categoryId)
            : [...value, categoryId];
        onChange(newValue);
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <label className="block text-sm font-medium">
                    {t.title}
                    <span className="text-error">*</span>
                </label>
                <span className="text-xs opacity-70">
                    {t.selectHint}
                </span>
            </div>

            {/* Selected Categories Preview */}
            <div className="flex flex-wrap gap-2">
                <AnimatePresence>
                    {value.map(categoryId => {
                        const category = SHOP_CATEGORIES.find(c => c.id === categoryId);
                        return (
                            <motion.div
                                key={categoryId}
                                initial={{scale: 0.8, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                                exit={{scale: 0.8, opacity: 0}}
                                className="badge badge-primary gap-2 p-3"
                            >
                                <span>{category.icon}</span>
                                <span>{category.translations[language].label}</span>
                                <button
                                    onClick={() => handleSelect(categoryId)}
                                    className="btn btn-ghost btn-xs btn-circle"
                                >
                                    ✕
                                </button>
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </div>

            {/* Category Selection Grid */}
            <div className="relative">
                <AnimatePresence>
                    <motion.div
                        initial={false}
                        animate={{
                            height: isExpanded ? 'auto' : '0px',
                            opacity: isExpanded ? 1 : 0
                        }}
                        transition={{duration: 0.3}}
                        className="grid grid-cols-1 sm:grid-cols-2 gap-3 overflow-hidden"
                    >
                        {SHOP_CATEGORIES.map(category => {
                            const isSelected = value.includes(category.id);
                            return (
                                <motion.button
                                    key={category.id}
                                    whileHover={{scale: 1.02}}
                                    whileTap={{scale: 0.98}}
                                    onClick={() => handleSelect(category.id)}
                                    className={`
                                        relative p-4 rounded-lg text-left transition-colors duration-200
                                        ${isSelected ? 'bg-primary text-primary-content' : 'bg-base-200 hover:bg-base-300'}
                                        group flex items-start gap-3 overflow-hidden
                                    `}
                                >
                                    {/* Selection Indicator */}
                                    <AnimatePresence>
                                        {isSelected && (
                                            <motion.div
                                                initial={{scale: 0}}
                                                animate={{scale: 1}}
                                                exit={{scale: 0}}
                                                className="absolute right-2 top-2"
                                            >
                                                <CheckCircle className="w-5 h-5"/>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                    {/* Category Content */}
                                    <span className="text-2xl">{category.icon}</span>
                                    <div className="flex-1 min-w-0">
                                        <h3 className="font-medium text-sm mb-1">
                                            {category.translations[language].label}
                                        </h3>
                                        <p className="text-xs opacity-70 line-clamp-2">
                                            {category.translations[language].description}
                                        </p>
                                    </div>

                                    {/* Hover Effect */}
                                    <div
                                        className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-10 transition-opacity"/>
                                </motion.button>
                            );
                        })}
                    </motion.div>
                </AnimatePresence>

                {/* Toggle Button */}
                <motion.button
                    animate={{opacity: 1}}
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="w-full h-12 flex items-center justify-center text-sm mt-2"
                >
                    {isExpanded ? t.showLess : t.showMore}
                    <motion.span
                        animate={{rotate: isExpanded ? 180 : 0}}
                        transition={{duration: 0.3}}
                        className="ml-1"
                    >
                        ▼
                    </motion.span>
                </motion.button>
            </div>

            {/* Error Message */}
            {error && (
                <motion.p
                    initial={{opacity: 0, y: -10}}
                    animate={{opacity: 1, y: 0}}
                    className="text-sm text-error mt-2 flex items-center gap-2"
                >
                    <TagIcon className="w-4 h-4"/>
                    {t.required}
                </motion.p>
            )}
        </div>
    );
};

export default ShopCategorySelector;