import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {db, storage} from '../firebase';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {nanoid} from 'nanoid';
import EmployeeForm from './EmployeeForm';
import {Camera, X} from 'lucide-react';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2';

const EmployeeRegisterPage = () => {
    const {shopId, token} = useParams();
    const navigate = useNavigate();
    const [shop, setShop] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState({
        name: '',
        photo: null,
        expertise: [],
        schedule: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: []
        }
    });

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setCurrentEmployee({
                ...currentEmployee,
                photo: acceptedFiles[0]
            });
        }
    });

    useEffect(() => {
        const validateToken = async () => {
            try {
                const shopDoc = await getDoc(doc(db, 'barberShops', shopId));
                if (!shopDoc.exists()) throw new Error('Shop not found');

                const shopData = shopDoc.data();
                setShop(shopData);

                const tokenData = shopData.employeeRegistrationTokens?.[token];
                if (!tokenData) throw new Error('Invalid token');

                const expiryDate = tokenData.expires.toDate();
                if (expiryDate < new Date()) throw new Error('Token expired');
                if (tokenData.used) throw new Error('Token already used');

                setIsTokenValid(true);
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'This registration link is invalid or has expired.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate('/');
                });
            } finally {
                setIsLoading(false);
            }
        };

        validateToken();
    }, [shopId, token, navigate]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            // Validate inputs
            if (!currentEmployee.name.trim()) {
                throw new Error('Name is required');
            }
            if (!currentEmployee.photo) {
                throw new Error('Photo is required');
            }
            if (currentEmployee.expertise.length === 0) {
                throw new Error('At least one expertise area is required');
            }
            if (!Object.values(currentEmployee.schedule).some(day => day.length > 0)) {
                throw new Error('Work schedule is required');
            }

            // Upload photo
            const photoRef = ref(storage, `shops/${shopId}/employees/${currentEmployee.name}-${nanoid(6)}`);
            await uploadBytes(photoRef, currentEmployee.photo);
            const photoUrl = await getDownloadURL(photoRef);

            // Create employee object
            const newEmployee = {
                ...currentEmployee,
                id: nanoid(),
                photo: photoUrl,
                registeredAt: new Date(),
                registrationToken: token
            };

            // Update shop document
            const shopRef = doc(db, 'barberShops', shopId);
            await updateDoc(shopRef, {
                employees: [...(shop.employees || []), newEmployee],
                [`employeeRegistrationTokens.${token}.used`]: true,
                [`employeeRegistrationTokens.${token}.usedBy`]: newEmployee.id,
                [`employeeRegistrationTokens.${token}.status`]: 'completed'
            });

            await Swal.fire({
                title: 'Success!',
                text: 'Your registration has been completed successfully.',
                icon: 'success',
                timer: 2000
            });

            navigate('/registration-success');
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message || 'Failed to complete registration. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="mt-4">Validating registration link...</p>
                </div>
            </div>
        );
    }

    if (!isTokenValid) return null;

    return (
        <div className="min-h-screen bg-base-100 py-12 px-4">
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                className="max-w-3xl mx-auto space-y-8"
            >
                <div className="text-center">
                    <motion.h1
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        className="text-3xl font-bold mb-2"
                    >
                        Join {shop.name}
                    </motion.h1>
                    <p className="text-base-content/70">
                        Complete your profile to join the team
                    </p>
                </div>

                <div className="card bg-base-100 shadow-xl">
                    <div className="card-body space-y-6">
                        {/* Name Input */}
                        <div>
                            <label className="label">
                                <span className="label-text">Your Name</span>
                            </label>
                            <input
                                type="text"
                                className="input input-bordered w-full"
                                value={currentEmployee.name}
                                onChange={e => setCurrentEmployee({
                                    ...currentEmployee,
                                    name: e.target.value
                                })}
                                placeholder="Enter your full name"
                            />
                        </div>

                        {/* Photo Upload */}
                        <div>
                            <label className="label">
                                <span className="label-text">Your Photo</span>
                            </label>
                            <div {...getRootProps()}
                                 className="border-2 border-dashed rounded-lg p-4 text-center cursor-pointer hover:border-primary transition-colors"
                            >
                                <input {...getInputProps()} />
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    className="relative inline-block"
                                >
                                    {currentEmployee.photo ? (
                                        <>
                                            <img
                                                src={URL.createObjectURL(currentEmployee.photo)}
                                                alt="Preview"
                                                className="w-32 h-32 rounded-lg object-cover"
                                            />
                                            <motion.button
                                                whileHover={{scale: 1.1}}
                                                whileTap={{scale: 0.9}}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setCurrentEmployee({
                                                        ...currentEmployee,
                                                        photo: null
                                                    });
                                                }}
                                                className="absolute -top-2 -right-2 bg-base-100 rounded-full shadow-lg p-1"
                                            >
                                                <X className="w-4 h-4"/>
                                            </motion.button>
                                        </>
                                    ) : (
                                        <div className="p-8">
                                            <Camera className="w-12 h-12 mx-auto text-base-content/40"/>
                                            <p className="mt-2 text-sm text-base-content/60">
                                                Upload your professional photo
                                            </p>
                                        </div>
                                    )}
                                </motion.div>
                            </div>
                        </div>

                        {/* Employee Form (Expertise & Schedule) */}
                        <EmployeeForm
                            employee={currentEmployee}
                            onUpdate={setCurrentEmployee}
                            language="en"
                        />

                        {/* Submit Button */}
                        <motion.button
                            whileHover={{scale: 1.02}}
                            whileTap={{scale: 0.98}}
                            className="btn btn-primary w-full"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <span className="loading loading-spinner"></span>
                            ) : (
                                'Complete Registration'
                            )}
                        </motion.button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default EmployeeRegisterPage;